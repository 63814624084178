import React, { Component } from 'react';
import "papercss/dist/paper.min.css";
import { withAuthenticator } from 'aws-amplify-react';
import Amplify, {API} from 'aws-amplify';

const oauth = {
  domain: 'ocvurlshort.auth.us-east-1.amazoncognito.com',
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: 'https://www.ocv.im/',
  redirectSignOut: 'https://www.ocv.im/logout',
  responseType: 'token' // or code
};

Amplify.configure({
  Auth: {
      oauth,
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: 'us-east-1:19366e0c-7541-4dbd-93bb-9aad9ea60141', 
      // REQUIRED - Amazon Cognito Region
      region: 'us-east-1', 
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-east-1_pDeBiaaIi',
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: '79kcd73onpis9jq3db6jvmqvtn', 
  },
  API: {
      endpoints: [
          {
              name: "dev-serverless-url-shortener",
              endpoint: "https://0e567lwijb.execute-api.us-east-1.amazonaws.com/dev",
          }
      ]
  }
});

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      longUrl: "",
      message: "",
    }
    this.handleChange = this.handleChange.bind(this);
    this.shortenLink = this.shortenLink.bind(this);
  }

  handleChange(event) {
    this.setState({longUrl: event.target.value});
  }

  shortenLink(event) {
    event.preventDefault();
    const {longUrl} = this.state;
    API.post("dev-serverless-url-shortener", "/", {
      body: {url: longUrl}
    }).then(response => {
      // Add your code here
      this.setState({message:  'https://ocv.im/' + response.path})
      console.log(response)
    }).catch(error => {
      this.setState({message: error.response.data.message});
      console.log(error.response.data.message)
    })
  }

  render() {
    return (
      <div className="row flex-center">
        <div className="col-8 col">
          <h2>serverless url shortener</h2>
          <form onSubmit={this.shortenLink}>
          <div className="form-group">
            <label htmlFor="url">Enter URL to shorten</label>
            <input value={this.state.longUrl} onChange={this.handleChange}  className="input-block" required/>
          </div>
          <div id="message" className="alert alert-primary">{this.state.message}</div>
          <input className="paper-btn" type="submit" value="Shorten link"/>
          </form>
        </div>
      </div>
    );
  }
}

export default withAuthenticator(App, {includeGreetings: true});
